<template>
  <div></div>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    components: {},
    data() {
      return {};
    },
    mounted() {
      this.UPDATE_FLAG_SHOW_OVERLAY(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY'])
    }
  };
</script>
<style></style>
